import { Component } from "react"
import { css } from '@emotion/react'
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI

const modTwoBack = css` 
  background-color : #ccc;
 `
 
class PrestamosPersonalesCmp extends Component {    

    render() {
        const {
            titleIcon='Préstamos',
            txtTitle='Apoyo Financiero hace que el pago de tu préstamo sea fácil.',
            txtContent='No te cobramos penalidades si decides liquidar tu préstamo.',
            txtContentOne=<div>Ofrecemos préstamos personales desde <strong>$750</strong> hasta <strong>$15,000*</strong> sin necesidad de empeñar.</div>,
            txtContentTwo='Contamos con diferentes plazos de pago según el monto y plazo del préstamo, por ejemplo:',
            txtLiOne='De 9 a 104 catorcenas.',
            txtLiTwo='De 8 a 96 quincenas.',
            txtLiThree='De 4 a 48 meses.',
            txtMain=<span>Te enviaremos un mensaje texto unos dias antes de la fecha de tu pago.<br/><br/>Préstamos a empleados y dueños de micronegocios que puedan comprobar sus ingresos. <br/><br/>Los pagos se adaptan a la frecuencia de tu ingreso, ya sea catorcenal, quincenal o mensual.<br/><br/>Los pagos son fijos y la tasa también.</span>,
            txtModTitle='Solo necesitas:',
            txtModMain=<span><strong>Identificación Oficial:</strong><br/><ul><li>California ID - Matrícula Consular - Pasaporte.</li></ul><strong>Comprobante de Ingresos:</strong><br/><ul><li>Cheque de tu empresa, talón de pago o Declaraciones de impuestos.</li></ul><strong>Número de Seguro Social</strong><br/><br/><strong>Comprobante de Domicilio:</strong><ul><li>Recibo de pago de servicios -luz, gas, teléfono- a tu nombre.</li></ul></span>            
        } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container >
                    <Grid item >
                        <Typography component='h3' variant="h4" >
                        <span style={{color:'#698d21'}}><strong>{titleIcon}</strong></span>                        
                        </Typography>
                          
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                    <CardElevation>
                    <Typography component='h4' variant="h6" >
                        <strong>{txtTitle}</strong>                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContent}                        
                    </Typography>
                    <br/><br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentOne}                        
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtContentTwo}                        
                    </Typography>
                    <br/><br/>
                    <Typography component='subtitle' variant="subtitle" >
                        <ul>
                            <li>{txtLiOne}</li>
                            <li>{txtLiTwo}</li>
                            <li>{txtLiThree}</li>
                           </ul>                         
                    </Typography>
                    <Typography component='subtitle' variant="subtitle" >
                      {txtMain}                        
                    </Typography>

                    </CardElevation>
                </Grid>
                <Grid item xs={12} sm={4} css={modTwoBack}>
                    <Typography component='h4' variant="h6" >
                       <strong>{txtModTitle}</strong> 
                    </Typography>
                    <br/>
                    <Typography component='subtitle' variant="subtitle" >
                       {txtModMain}
                    </Typography>                    
                </Grid>
                </Grid>
            </Container>

        )
    }
}

PrestamosPersonalesCmp.propTypes = {
    txtTitle: PropTypes.string,        
    txtContent: PropTypes.string,
    txtContentOne: PropTypes.string,
    txtContentTwo: PropTypes.string,
    txtLiOne: PropTypes.string,
    txtLiTwo: PropTypes.string,
    txtLiThree: PropTypes.string,
    txtMain: PropTypes.string,
    txtModTitle: PropTypes.string,
    txtModMain: PropTypes.string
}

export default withThemeProps(PrestamosPersonalesCmp, 'VDPNPrestamosPersonales')






